import { TEN_MB, ONE_HUNDRED_MB, ONE_GB } from "@es/core/constants.js";

export const GalleryItemType = {
  IMAGE: "image",
  VIDEO: "video",
} as const;

export const EventExportStatus = {
  DONE: "done",
  EXPIRED: "expired",
  PROCESSING: "processing",
} as const;

export const BadFileUploadStatus = {
  PENDING_DELETE: "pending_delete",
  DELETED: "deleted",
} as const;

export const HostAudioGreetingStatus = {
  ACTIVE: "active",
  PENDING_DELETE: "pending_delete",
  DELETED: "deleted",
} as const;

export const GalleryItemStatus = {
  PUBLIC: "public",
  PRIVATE: "private",
  TRASH: "trash",
  PENDING_DELETE: "pending_delete",
  DELETED: "deleted",
} as const;

export const GuestMessageStatus = {
  VIEWABLE: "viewable",
  PENDING_DELETE: "pending_delete",
  DELETED: "deleted",
} as const;

// QR code
export const QrStyle = {
  SQUARES: "squares",
  DOTS: "dots",
} as const;

export const QrLogoPaddingStyle = {
  SQUARE: "square",
  CIRCLE: "circle",
} as const;

export const EventTier = {
  FREE: "free",
  PAID: "paid",
} as const;

export const AccountTier = {
  FREE: "free",
  PAID: "paid",
} as const;

export const EventType = {
  WEDDING: "wedding",
  BIRTHDAY: "birthday",
  HOLIDAY: "holiday",
  GRADUATION: "graduation",
  CORPORATE_GATHERING: "corporate",
  OTHER: "other",
} as const;

export const ScheduledChangeAction = {
  CANCEL: "cancel",
  PAUSE: "pause",
  RESUME: "resume",
  // this is not an offical paddle scheduled option
  DOWNGRADE: "downgrade",
} as const;

export const CreditTier = {
  FREE: 30,
  GROWTH: 5000,
  PROFESSIONAL: 10000,
  BUSINESS: 40000,
} as const;

export const StorageTier = {
  FREE: 3 * TEN_MB,
  GROWTH: ONE_HUNDRED_MB + 5 * TEN_MB,
  PROFESSIONAL: 2 * ONE_HUNDRED_MB + 5 * TEN_MB,
  BUSINESS: ONE_GB,
} as const;

export const MembershipRole = {
  ADMIN: "admin",
  OWNER: "owner",
} as const;

export const MembershipStatus = {
  PENDING: "pending",
  ACTIVE: "active",
  INACTIVE: "inactive",
  CANCELED: "canceled",
} as const;

export const UserStatus = {
  ONBOARDING: "onboarding",
  ONBOARDED: "onboarded",
} as const;

export const OrganizationStatus = {
  ACTIVE: "active",
  DELETED: "deleted",
} as const;

export const EventStatus = {
  ACTIVE: "active",
  EXPIRED: "expired",
  PENDING_DELETE: "pending_delete",
  DELETED: "deleted",
} as const;

export const SubscriptionStatus = {
  ACTIVE: "active",
  TRIALING: "trialing",
  PAST_DUE: "past_due",
  PAUSED: "paused",
  CANCELED: "canceled",
} as const;

export const TransactionStatus = {
  DRAFT: "draft",
  READY: "ready",
  BILLED: "billed",
  COMPLETED: "completed",
  CANCELED: "canceled",
  PAST_DUE: "past_due",
} as const;

export const SlideshowContent = {
  IMAGE: "image",
  VIDEO: "video",
  IMAGE_VIDEO: "image_video",
} as const;

export const SlideshowTheme = {
  PLAIN: "plain",
  POLAROID: "polaroid",
} as const;

export const SlideshowBackgroundImages = {
  NONE: "none",
  SCROLL: "scroll",
  FADE: "fade",
} as const;

export const OrderStatus = {
  PAID: "paid",
  REFUNDED: "refunded",
} as const;

export const UploadLimitType = {
  PERSON: "person",
  ITEM: "item",
} as const;

export const OnboardingChecklistItem = {
  CREATE_EVENT: "create_event",
  UPLOAD_TO_GALLERY: "upload_to_gallery",
  CUSTOMIZE_BANNER_IMAGE: "custom_banner_image",
  SHARE_WITH_SOMEONE: "share_with_someone",
} as const;

export const EmailType = {
  EVENT_EXPIRE_5_DAYS: "event_expire_5_days",
  EVENT_EXPIRE_3_DAYS: "event_expire_3_days",
  EVENT_EXPIRE_1_DAYS: "event_expire_1_days",
  EVENT_ZIP_COMPLETE: "event_zip_complete",
} as const;

export const EmailStatus = {
  PENDING: "pending",
  SENT: "sent",
};
